import { useState, useEffect, useCallback, useMemo } from 'react';

import { StorageManager } from '@/lib/StorageManager';
import { useFirebaseAuthContext } from '@/context/FirebaseAuthContext';

import { PlanMenuInfo, PlanInfo, FolderInfo, PlanDefinition, allPlansOption, timelineOption } from "@/lib/interfaces";
import logger from "@/lib/logger";
import { addItemToPlans, addUpdatedPlanIdToCache, findFirstItemByType, findPlanItemAndParentById, generateDefaultMilestones } from '@/lib/utils';

import { useNetworkStatus } from './useNetworkStatus';
import { v4 as uuidv4 } from 'uuid';
import { logEvent } from 'firebase/analytics';
import { getFirebaseAnalytics } from './firebase';
import { sendEmailMessageForNewPlan } from '@/ui/components/EmailMessage';
import { generateRandomIcon } from '../IconUtils';

export const usePlansProvider = () => {
    const storageManager = useMemo(() => new StorageManager(), []);
    const isOnline = useNetworkStatus();

    const { user } = useFirebaseAuthContext();

    const [plans, setPlans] = useState<PlanMenuInfo[]>([]);
    const [isFetchingPlans, setIsFetchingPlans] = useState<boolean>(false);
    const [fetchPlansError, setFetchError] = useState<Error | null>(null);

    const [selectedPlan, setSelectedPlan] = useState<PlanMenuInfo | null>(null);
    const [selectedMilestoneId, setSelectedMilestoneId] = useState<string | null>(null);

    const [planHeaderChangeCounter, setPlanHeaderChangeCounter] = useState(0);
    const [planDetailChangeCounter, setPlanDetailChangeCounter] = useState(0);

    // Whenever selectedPlan changes, update it in local storage
    useEffect(() => {
        logger.debug('usePlans selectedPlan', selectedPlan);

        if (typeof window !== 'undefined') {
            if (selectedPlan) {
                localStorage.setItem('selectedPlanId', selectedPlan.id);
            }

            if (selectedMilestoneId) {
                localStorage.setItem('selectedMilestoneId', selectedMilestoneId);
            } else {
                localStorage.removeItem('selectedMilestoneId');
            }
        }
    }, [selectedPlan, selectedMilestoneId]);

    const fetchPlans = useCallback(async () => {
        logger.debug('Fetching plans for user', user?.uid);

        if (!user?.uid || !isOnline) return;

        setIsFetchingPlans(true);
        setFetchError(null);

        try {
            const storedPlans: PlanMenuInfo[] = await storageManager.get('users', user?.uid, 'timelines') || [];
            setPlans(storedPlans);

            const lastSelectedPlanId = localStorage.getItem('selectedPlanId');

            if (lastSelectedPlanId === 'all_plans') {
                setSelectedPlan(allPlansOption);
            } else if (lastSelectedPlanId === 'timeline') {
                setSelectedPlan(timelineOption);
            } else {
                // Set the initial selected plan (e.g., the first one)
                if (storedPlans.length > 0) {
                    var lastSelectedPlan: PlanInfo | null = null;

                    if (lastSelectedPlanId) {
                        lastSelectedPlan = findPlanItemAndParentById(storedPlans, lastSelectedPlanId)?.item as PlanInfo;
                    }

                    if (lastSelectedPlan) {
                        setSelectedPlan(lastSelectedPlan);
                    } else {
                        const result = findFirstItemByType(storedPlans, 'plan');

                        if (result) {
                            lastSelectedPlan = result.item as PlanInfo;
                            const parentFolder = result.parent as FolderInfo;

                            if (lastSelectedPlan) {
                                setSelectedPlan(lastSelectedPlan);

                                if (parentFolder && !parentFolder.isExpanded) {
                                    parentFolder.isExpanded = true;
                                }
                            }
                        }
                    }
                }

                const lastSelectMilestoneId = localStorage.getItem('selectedMilestoneId');

                if (lastSelectMilestoneId) {
                    setSelectedMilestoneId(lastSelectMilestoneId);
                }
            }
        } catch (error) {
            logger.error('Error fetching plans:', error);
            setFetchError(error as Error);
        } finally {
            setIsFetchingPlans(false);
        }
    }, [user?.uid, isOnline, storageManager]);

    const handleSelectedPlanChange = useCallback((newPlans) => {
        if (selectedPlan) {
            const result = findPlanItemAndParentById(newPlans, selectedPlan.id);

            if (result) {
                const { item } = result;

                if (selectedPlan?.id === item.id) {
                    logger.debug('Updating selected plan', item);
                    setSelectedPlan(item as PlanInfo);
                }
            }
        }
    }, [selectedPlan, setSelectedPlan]);

    const savePlans = useCallback(async (updatedPlans: PlanMenuInfo[]): Promise<void> => {
        if (!user?.uid) return;

        logger.debug('Saving plans for user', user?.uid);
        
        try {
            await storageManager.set('users', user?.uid, updatedPlans, 'timelines');
            setPlans(updatedPlans);

            handleSelectedPlanChange(updatedPlans);
        } catch (error) {
            logger.error('Error saving plans:', error);
        }
    }, [user?.uid, storageManager, handleSelectedPlanChange]);

    useEffect(() => {
        if (user) {
            logger.debug('User logged in, fetching plans');
            fetchPlans();
        } else {
            setPlans([]);
            setSelectedPlan(null);
            setSelectedMilestoneId(null);
        }
    }, [user, fetchPlans]);

    const createPlan = useCallback(async (planDefinition: PlanDefinition, folder: FolderInfo): Promise<PlanInfo> => {
        const newPlanInfo: PlanInfo = {
            id: uuidv4(),
            type: 'plan',
            title: planDefinition.title,
            init: planDefinition.init,
            description: planDefinition.description,
            creationDate: new Date().toISOString(),
            updatedDate: new Date().toISOString(),
            icon: generateRandomIcon(),
        };

        logger.debug('newPlan', newPlanInfo);

        let targetFolderId = folder?.id ?? null;

        const updatedPlans = addItemToPlans(plans || [], newPlanInfo, targetFolderId);

        if (user?.uid) {
            await storageManager.set('users', user?.uid, updatedPlans, 'timelines');

            // Set the owner of the plan
            await storageManager.set('timelines', newPlanInfo.id, user?.uid, 'ownerId');

            // Set the plans milestones
            if (planDefinition.events && planDefinition.events.length > 0) {
                await storageManager.set('timelines', newPlanInfo.id, planDefinition.events, 'events');
            } else {
                await storageManager.set('timelines', newPlanInfo.id, generateDefaultMilestones(), 'events');
            }

            // Set the plans OKRs
            if (planDefinition.okrs && planDefinition.okrs.length > 0) {
                await storageManager.set('timelines', newPlanInfo.id, planDefinition.okrs, 'okrs');
            }

            // Set the plans icons
            if (planDefinition.icons) {
                await storageManager.set('timelines', newPlanInfo.id, planDefinition.icons, 'icons');
            }

            // Set the plans retro notes
            if (planDefinition.retros && planDefinition.retros.length > 0) {
                await storageManager.set('timelines', newPlanInfo.id, planDefinition.retros, 'retros');
            }

            logEvent(getFirebaseAnalytics(), 'new_plan_created');

            sendEmailMessageForNewPlan(newPlanInfo, planDefinition, user);
        }

        setPlans(updatedPlans);

        return newPlanInfo;
    }, [plans, user, storageManager]);

    const updatePlan = useCallback(async (updatedPlan: PlanInfo, flagAsHeaderChange = false, flagAsDetailChange = false) => {
        const result = findPlanItemAndParentById(plans, updatedPlan.id);

        if (result) {
            const { parent, index } = result;

            // no need to do a deep copy, since by creating newPlans, we are creating a new reference
            // The new reference is good enough to make React re-render the component
            //
            // Triggering Re-renders: React's re-render mechanism on state update works by checking if the new state is
            // different from the old state. For arrays and objects, this is a reference equality check. 
            // If you mutate the array directly and then set it using setPlans, React may not trigger a re-render 
            // because it sees the same array reference.
            let newPlans = [...plans];

            if (parent) {
                // Update the plan within its parent's context
                const updatedItems = [...parent.items];
                updatedItems[index] = updatedPlan;
                parent.items = updatedItems;

                // Find and update the parent item in newPlans
                const parentIndex = newPlans.findIndex(p => p.id === parent.id);
                if (parentIndex !== -1) {
                    newPlans[parentIndex] = parent;
                }
            } else {
                // Update the top-level plan directly
                newPlans[index] = updatedPlan;
            }

            setPlans(newPlans);

            // If someonthing changes about the currently selected plan
            // signal that in the UI for re-renders
            if (selectedPlan?.id === updatedPlan.id) {
                logger.debug('Updating selected plan', updatedPlan);
                setSelectedPlan(updatedPlan);
            }

            if (user) {
                await storageManager.set('users', user.uid, newPlans, 'timelines');
                addUpdatedPlanIdToCache(user.uid, updatedPlan.id);
            }

            if (flagAsHeaderChange) {
                setPlanHeaderChangeCounter(prevCounter => {
                    const newCounter = prevCounter + 1;

                    logger.debug("Header change counter:", newCounter);

                    return newCounter;
                });
            }

            if (flagAsDetailChange) {
                setPlanDetailChangeCounter(prevCounter => {
                    const newCounter = prevCounter + 1;
                    logger.debug("Detail change counter:", newCounter);
                    return newCounter;
                });
            }
        }

    }, [plans, selectedPlan?.id, storageManager, user]);

    const deletePlan = useCallback(async (plan: PlanMenuInfo) => {
        let updatedPlans = [...plans];
        logger.debug('Plans before deleting', updatedPlans);

        const result = findPlanItemAndParentById(updatedPlans, plan.id);

        if (result) {
            const { parent, index } = result;

            if (parent) {
                // Remove the plan from its parent
                parent.items.splice(index, 1);
            } else {
                // The plan is a top-level item
                updatedPlans.splice(index, 1);
            }

            // Update the state with the new plans array
            setPlans([...updatedPlans]);

            logger.debug('Plans after deleting', updatedPlans);

            if (selectedPlan && selectedPlan.id === plan.id) {
                const firstPlan = findFirstItemByType(updatedPlans, 'plan');

                if (firstPlan) {
                    const planInfo = firstPlan.item as PlanInfo;
                    setSelectedPlan(planInfo);
                } else {
                    logger.debug('No non-folder plans found, setting selectedPlan to null');
                    setSelectedPlan(null);
                }
            }

            if (user) {
                await storageManager.set('users', user.uid, updatedPlans, 'timelines');
            }

            if (plan.type === 'plan') {
                // Delete everything associated with the plan
                await storageManager.delete('timelines', plan.id);
                logEvent(getFirebaseAnalytics(), 'plan_deleted');
            }
        }
    }, [user, plans, selectedPlan, storageManager]);

    return {
        plans,
        setPlans,
        savePlans,
        createPlan,
        updatePlan,
        deletePlan,
        selectedPlan,
        setSelectedPlan,
        selectedMilestoneId,
        setSelectedMilestoneId,
        isFetchingPlans,
        fetchPlansError,
        planHeaderChangeCounter,
        planDetailChangeCounter,
    };
};
