import { useState, useEffect } from 'react';

export const useNetworkStatus = () => {
    // Initialize as true or false based on the environment
    const [isOnline, setIsOnline] = useState(typeof navigator !== 'undefined' ? navigator.onLine : true);

    useEffect(() => {
        // Ensure navigator is defined before adding event listeners
        if (typeof navigator !== 'undefined') {
            const handleOnline = () => setIsOnline(true);
            const handleOffline = () => setIsOnline(false);

            window.addEventListener('online', handleOnline);
            window.addEventListener('offline', handleOffline);

            return () => {
                window.removeEventListener('online', handleOnline);
                window.removeEventListener('offline', handleOffline);
            };
        }
    }, []);

    return isOnline;
};
