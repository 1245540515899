import React, { createContext, useContext, ReactNode } from 'react';
import { User } from 'firebase/auth';
import { useFirebaseAuth } from '@/lib/hooks/firebase';

interface FirebaseAuthContextValue {
  user: User | null;
  isUserLoading: boolean;
  userError: Error | null;
}

const FirebaseAuthContext = createContext<FirebaseAuthContextValue | undefined>(undefined);

interface FirebaseAuthProviderProps {
  children: ReactNode;
}

export const FirebaseAuthProvider = ({ children }: FirebaseAuthProviderProps) => {
    const { user, isUserLoading, userError } = useFirebaseAuth();

    return (
        <FirebaseAuthContext.Provider value={{ user, isUserLoading, userError }}>
            {children}
        </FirebaseAuthContext.Provider>
    );
};

export const useFirebaseAuthContext = () => {
  const context = useContext(FirebaseAuthContext);
  if (context === undefined) {
    throw new Error('useFirebaseAuthContext must be used within a FirebaseAuthProvider');
  }
  return context;
};
