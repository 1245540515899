export interface MilestoneDates {
    startDate: Date;
    endDate: Date;
    changed?: boolean;
}

export interface PlanMilestoneData {
    id: string;
    startDate: string;
    endDate: string;
    description: string;
    collapsed?: boolean;
    content?: string;
    priority?: number;
}

export const TIMELINE_LAYER_HEIGHT = 85; // Define a constant for the layer height

export interface TimelineMilestoneData extends PlanMilestoneData {
    plan: PlanInfo;
    progress: number;
    okr: OKRData;
    displayStartDate: string;
    displayEndDate: string;
}

export interface TimelineData {
    milestonesByPlan: { [planId: string]: TimelineMilestoneData[] };
}

export interface TimelineLayerItem {
    milestone: TimelineMilestoneData;
    startPercent: number;
    endPercent: number;
    widthPercent: number;
    layerIndex: number;
}

export interface DragMilestoneItem {
    id: string;
    index: number;
    objectiveText: string;
    iconSelections: { [key: string]: IconSelectionInfo };
    width?: number;
}

export interface DragTimelineMilestoneItem {
    layerIndex: number;
    objectiveText: string;
    isIntimeline: boolean;
    iconSelections: { [key: string]: IconSelectionInfo };
    milestone: TimelineMilestoneData;
}

export enum PlanSideViewType {
    Vision,
    Mindset,
    // Future types can be added here
}

export const allPlansOption: AllPlansOption = {
    id: 'all_plans',
    title: 'All Plans',
    type: 'all_plans',
    creationDate: new Date().toISOString()
};

export const timelineOption: TimelineOption = {
    id: 'timeline',
    title: 'Priorities',
    type: 'timeline',
    creationDate: new Date().toISOString()
};

export interface PlanItemInfo {
    id: string;
    title: string;
    icon?: IconSelectionInfo | null;
    creationDate: string;
    type: 'plan' | 'folder' | 'all_plans' | 'timeline';
    archived?: boolean;
}

export interface PlanInfo extends PlanItemInfo {
    type: 'plan';
    updatedDate: string;
    init?: PlanCreationInfo;
    description?: string;
    progressInfo?: ProgressInfo;
    shouldShowDescription?: boolean | null;
    shouldShowProgress?: boolean | null;
    sideViewType?: PlanSideViewType | null;
}

export interface FolderInfo extends PlanItemInfo {
    type: 'folder';
    items: Array<PlanMenuInfo>;
    isExpanded?: boolean;
}

export interface AllPlansOption extends PlanItemInfo {
    id: 'all_plans';
    title: string;
    type: 'all_plans';
}

export interface TimelineOption extends PlanItemInfo {
    id: 'timeline';
    title: string;
    type: 'timeline';
}

export type PlanMenuInfo = PlanInfo | FolderInfo | AllPlansOption | TimelineOption;

export interface ProgressInfo {
    startDate: string;
    endDate: string;
    progress: number;
}

export interface PlanSnapshot {
    id: string;
    url: string;
    date: string;
}

export interface KeyResult {
    description: string;
    progress: number;
    scratched?: boolean;
    contentId?: string,
}

export interface OKRData {
    id: string;
    objective: string;
    contentId?: string,
    keyResults: KeyResult[];
    filterSettings?: {
        notStarted: boolean;
        inProgress: boolean;
        completed: boolean;
        scratched: boolean;
    };
}

export interface RetroData {
    id: string;
    title: string;
    note: string;
    bgColor: string;
    textColor: string;
}

export interface PlanCreationInfo {
    mode: string;
    startDate: string;
    milestoneDuration: string;
    numberOfMilestones: number;
    userPrompt?: string;
}

export interface PlanDefinition {
    title: string;
    description: string;
    events: PlanMilestoneData[];
    okrs: OKRData[];
    retros: RetroData[];
    icons: { [key: string]: IconSelectionInfo };
    init?: PlanCreationInfo;
    durationInDays?: number;
}

export interface EmojiInfo {
    name: string;
    short_name: string;
    unified: string;
    category: string;
    subcategory: string;
    sort_order: number;
    skin_variations?: Record<string, { unified: string }>;
}

export interface IconInfo {
    name: string;
    category: string;
}

export interface IconSelectionInfo {
    name: string;
    isEmoji: boolean;
    color?: string;
    backgroundColor?: string;
}

export type EmojiOrIconInfo = EmojiInfo | IconInfo;

export const DragItemTypes = {
    MILESTONE: 'milestone',
    TIMELINE_MILESTONE: 'timelineMilestone',
    KEY_RESULT: 'keyResult',
    PLAN: 'plan',
    FOLDER: 'folder'
};

export enum PredefinedTimeRange {
    ThisMonth = 'This Month',
    LastMonth = 'Last Month',
    ThisQuarter = 'This Quarter',
    LastQuarter = 'Last Quarter',
    ThisHalfYear = 'This Half Year',
    LastHalfYear = 'Last Half Year',
    ThisWeek = 'This Week',
    LastWeek = 'Last Week',
    ThisYear = 'This Year',
    LastYear = 'Last Year',
    AllTime = 'All Time',
}

export type CustomTimeRange = { type: 'Custom', startDate: string, endDate: string };

export type TimeRange = PredefinedTimeRange | CustomTimeRange;

export const isCustomTimeRange = (timeRange: TimeRange): timeRange is CustomTimeRange => {
    return (timeRange as CustomTimeRange).type === 'Custom';
};

// This represents the minimal metadata stored in the user's priorities document
export interface PrioritiesSnapshotReference {
    id: string;
    createdAt: string;
    timeRange: TimeRange;
}

// This represents the full snapshot data stored in a separate Firestore document
export interface PrioritiesSnapshotData {
    id: string;
    userId: string;
    timeRange: TimeRange;
    createdAt: string;
    milestones?: TimelineMilestoneData[];
}

export interface UserPrioritiesDocument {
    userId: string;
    snapshotRefs: PrioritiesSnapshotReference[];
}
