import React, { useState, useEffect, useCallback } from 'react';
import { IconButton, Tooltip, TextField, Typography, Popover } from '@mui/material';
import * as IconsMaterial from '@mui/icons-material';
import { EmojiInfo, IconSelectionInfo, PlanMilestoneData } from './interfaces';
import { SketchPicker } from 'react-color';

import Diversity1OutlinedIcon from '@mui/icons-material/Diversity1Outlined';
import PaletteIcon from '@mui/icons-material/Palette';
import FormatColorFillOutlinedIcon from '@mui/icons-material/FormatColorFillOutlined';
import ShuffleOutlinedIcon from '@mui/icons-material/ShuffleOutlined';

import { Tabs, Tab, Box } from '@mui/material';
import { FixedSizeList as List } from 'react-window';

import logger from "@/lib/logger";
import { toast } from "sonner";

import chroma from 'chroma-js';

const presetColors = [
    '#f6f6f8',
    '#fdf8b9',
    '#ecd14f',
    '#eaa257',
    '#def39e',
    '#cfdc6b',
    '#a7ce80',
    '#89c3c0',
    '#f3d1df',
    '#d89bb9',
    '#bea5cf',
    '#dc999e',
    '#b2ccf2',
    '#94d5f7',
    '#a2abf9',
    '#000000',
];


export const IconCategories = {
    BasicActions: [
        "FavoriteOutlined",
        "FavoriteBorderOutlined",
        "AddCircleOutline",
        "RemoveCircleOutline",
        "AutoFixHighOutlined",
        "AutorenewOutlined",
        "CheckOutlined",
        "DeleteOutlined",
        "EditOutlined",
        "FlutterDashOutlined",
        "ApprovalOutlined",
        "AppShortcutOutlined",
        "CreateOutlined",
        "FilterVintageOutlined",
        "FlashlightOnOutlined",
        "HelpOutlineOutlined",
        "QrCode2Outlined",
        "PushPinOutlined",
        "StarOutline",
        "More",
        "ChecklistOutlined",
        "ApiOutlined",
        "ChangeHistoryOutlined",
        "CheckroomOutlined",
        "RemoveRedEyeOutlined",
        "PolylineOutlined",
        "LayersOutlined",
        "GradientOutlined",
        "FollowTheSignsOutlined",
        "Filter1Outlined",
        "Filter2Outlined",
        "Filter3Outlined",
        "Filter4Outlined",
        "Filter5Outlined",
        "Filter6Outlined",
        "Filter7Outlined",
        "Filter8Outlined",
        "Filter9Outlined",
        "ErrorOutlineOutlined",
        "EmojiFlagsOutlined",
        "DiamondOutlined",
        "DataObjectOutlined",
    ],
    TimeAndScheduling: [
        "AccessTimeOutlined",
        "AlarmOutlined",
        "HourglassEmptyOutlined",
        "HourglassFullOutlined",
        "HourglassBottomOutlined",
        "CalendarMonthOutlined",
        "BedtimeOutlined",
    ],
    SocialAndPeople: [
        "AccountCircleOutlined",
        "FamilyRestroomOutlined",
        "GroupOutlined",
        "PersonOutlineOutlined",
        "PeopleAltOutlined",
        "PersonPinCircleOutlined",
        "ChildCareOutlined",
        "ChildFriendlyOutlined",
        "EmojiPeopleOutlined",
        "FaceOutlined",
        "Face2Outlined",
        "Face3Outlined",
        "Face4Outlined",
        "Face5Outlined",
        "Face6Outlined",
        "Diversity1Outlined",
        "Diversity2Outlined",
        "Diversity3Outlined",
        "Person2Outlined",
        "Person3Outlined",
        "Person4Outlined",
        "EscalatorWarningOutlined",
        "EmojiEmotionsOutlined",
    ],
    HealthAndWellness: [
        "SelfImprovementOutlined",
        "DirectionsWalkOutlined",
        "DirectionsRunOutlined",
        "BabyChangingStationOutlined",
        "CleanHandsOutlined",
        "FitnessCenterOutlined",
        "HealthAndSafetyOutlined",
        "SpaOutlined",
        "SportsBasketballOutlined",
        "SportsCricketOutlined",
        "SportsEsportsOutlined",
        "SportsGymnasticsOutlined",
        "SportsHandballOutlined",
        "SportsKabaddiOutlined",
        "SportsMartialArtsOutlined",
        "SportsMotorsportsOutlined",
        "SportsSoccerOutlined",
        "SportsVolleyballOutlined",
        "SanitizerOutlined",
        "PsychologyAltOutlined",
        "PsychologyOutlined",
        "RowingOutlined",
        "PregnantWomanOutlined",
        "EmojiEventsOutlined",
    ],
    Business: [
        "AttachMoneyOutlined",
        "BalanceOutlined",
        "BusinessCenterOutlined",
        "CardGiftcardOutlined",
        "PieChartOutlined",
        "SavingsOutlined",
        "ScaleOutlined",
        "CurrencyBitcoinOutlined",
        "TrendingUpOutlined",
        "AnalyticsOutlined",
        "PointOfSaleOutlined",
        "RequestQuoteOutlined",
        "SellOutlined",
        "StorefrontOutlined",
        "TrendingDownOutlined",
        "TrendingFlatOutlined",
        "WorkOutlineOutlined",
        "WorkspacesOutlined",
        "CampaignOutlined",
        "PublicOutlined",
        "InsertDriveFileOutlined",
        "InsightsOutlined",
    ],
    HomeAndLiving: [
        "AcUnitOutlined",
        "BedOutlined",
        "ChairOutlined",
        "ChairAltOutlined",
        "BedroomBabyOutlined",
        "BlenderOutlined",
        "BungalowOutlined",
        "CabinOutlined",
        "ChaletOutlined",
        "CottageOutlined",
        "DeckOutlined",
        "FireplaceOutlined",
        "HolidayVillageOutlined",
        "HouseOutlined",
        "HouseSidingOutlined",
        "KitchenOutlined",
        "RoofingOutlined",
        "DiningOutlined",
        "ApartmentOutlined",
        "BathtubOutlined",
        "AnchorOutlined",
        "CastleOutlined",
        "FortOutlined",
        "YardOutlined",
        "PetsOutlined",
        "EmojiObjectsOutlined",
    ],
    FoodAndDining: [
        "CakeOutlined",
        "FastfoodOutlined",
        "LocalCafeOutlined",
        "LocalDiningOutlined",
        "BrunchDiningOutlined",
        "DinnerDiningOutlined",
        "EmojiFoodBeverageOutlined",
        "IcecreamOutlined",
        "LunchDiningOutlined",
        "RoomServiceOutlined",
        "TakeoutDiningOutlined",
        "TableBarOutlined",
    ],
    SafetyAndSecurity: [
        "SecurityOutlined",
        "PrivacyTipOutlined",
        "VerifiedOutlined",
        "ShieldOutlined",
        "SafetyDividerOutlined",
        "LockOutlined",
        "PasswordOutlined",
        "BugReportOutlined",
        "EmergencyShareOutlined",
        "FireExtinguisherOutlined",
        "FireTruckOutlined",
        "GavelOutlined",
        "SecurityUpdateOutlined",
        "SecurityUpdateGoodOutlined",
        "SecurityUpdateWarningOutlined",
        "FireHydrantAltOutlined",
        "CoronavirusOutlined",
        "PhishingOutlined",
        "KeyOutlined",
    ],
    TravelAndTourism: [
        "AirplanemodeActiveOutlined",
        "AirportShuttleOutlined",
        "DirectionsBoatFilledOutlined",
        "DirectionsCarOutlined",
        "DirectionsTransitOutlined",
        "LocalTaxiOutlined",
        "MapOutlined",
        "TramOutlined",
        "TrainOutlined",
        "SubwayOutlined",
        "DirectionsBusOutlined",
        "DirectionsSubwayOutlined",
        "CompassCalibrationOutlined",
        "FlightOutlined",
        "HotelOutlined",
        "KayakingOutlined",
        "ParaglidingOutlined",
        "SailingOutlined",
        "SnowmobileOutlined",
        "DownhillSkiingOutlined",
        "IceSkatingOutlined",
        "ScubaDivingOutlined",
        "DirectionsBikeOutlined",
        "ElectricCarOutlined",
        "LocalGasStationOutlined",
        "LocalGroceryStoreOutlined",
        "LocalLibraryOutlined",
        "LocalShippingOutlined",
        "TwoWheelerOutlined",
        "PlaceOutlined",
        "TrafficOutlined",
        "CommuteOutlined",
        "PedalBikeOutlined",
        "DirectionsRailwayOutlined",
        "FmdGoodOutlined",
    ],
    EducationAndLearning: [
        "AccountTreeOutlined",
        "ArchitectureOutlined",
        "ArticleOutlined",
        "BatchPredictionOutlined",
        "BookOutlined",
        "CastForEducationOutlined",
        "LibraryAddCheckOutlined",
        "LibraryBooksOutlined",
        "LibraryMusicOutlined",
        "MenuBookOutlined",
        "SchoolOutlined",
        "ScienceOutlined",
        "ScoreboardOutlined",
    ],
    LifestyleAndLeisure: [
        "CameraAltOutlined",
        "HeadsetOutlined",
        "MovieOutlined",
        "PhotoCameraOutlined",
        "VideogameAssetOutlined",
        "AllInclusiveOutlined",
        "BeachAccessOutlined",
        "CasinoOutlined",
        "CelebrationOutlined",
        "EventOutlined",
        "GolfCourseOutlined",
        "HikingOutlined",
        "HotTubOutlined",
        "KitesurfingOutlined",
        "NightlifeOutlined",
        "PartyModeOutlined",
        "PoolOutlined",
        "SkateboardingOutlined",
        "SleddingOutlined",
        "SnowboardingOutlined",
        "SnowshoeingOutlined",
        "SportsOutlined",
        "SurfingOutlined",
        "TourOutlined",
        "BallotOutlined",
        "CategoryOutlined",
        "GestureOutlined",
        "RocketLaunchOutlined",
        "RocketOutlined",
        "SuperscriptOutlined",
        "TerminalOutlined",
        "FestivalOutlined",
        "TheaterComedyOutlined",
        "ToysOutlined",
        "MusicNoteOutlined",
        "PianoOutlined",
    ],
    ToolsAndHardware: [
        "BuildOutlined",
        "CarpenterOutlined",
        "ConstructionOutlined",
        "ElectricMopedOutlined",
        "ElectricRickshawOutlined",
        "ElectricalServicesOutlined",
        "EngineeringOutlined",
        "HandymanOutlined",
        "HardwareOutlined",
        "HomeRepairServiceOutlined",
        "PlumbingOutlined",
        "PowerOutlined",
        "PowerSettingsNewOutlined",
        "CarRepairOutlined",
        "PrecisionManufacturingOutlined",
    ],
    NatureAndEnvironment: [
        "BrightnessHighOutlined",
        "BrightnessLowOutlined",
        "BrightnessMediumOutlined",
        "Co2Outlined",
        "CycloneOutlined",
        "FilterDramaOutlined",
        "FloodOutlined",
        "ForestOutlined",
        "GrainOutlined",
        "GrassOutlined",
        "LandscapeOutlined",
        "StormOutlined",
        "ThermostatOutlined",
        "ThunderstormOutlined",
        "TornadoOutlined",
        "WaterOutlined",
        "ParkOutlined",
        "WaterDropOutlined",
        "WbCloudyOutlined",
        "WbIncandescentOutlined",
        "WbSunnyOutlined",
        "WbTwilightOutlined",
        "FlareOutlined",
        "PestControlOutlined",
        "PestControlRodentOutlined",
        "TerrainOutlined",
        "EggOutlined",
        "NaturePeopleOutlined",
        "WindPowerOutlined",
        "RecyclingOutlined",
        "HiveOutlined",
    ],
    ArtAndDesign: [
        "AnimationOutlined",
        "AutoAwesomeOutlined",
        "BrushOutlined",
        "DesignServicesOutlined",
        "DrawOutlined",
        "FormatPaintOutlined",
        "PaletteOutlined",
        "PhotoOutlined",
        "PhotoFilterOutlined",
        "PhotoLibraryOutlined",
        "PictureAsPdfOutlined",
        "ViewInArOutlined",
    ],
    ReligionAndSpirituality: [
        "ChurchOutlined",
        "MosqueOutlined",
        "SynagogueOutlined",
        "TempleBuddhistOutlined",
        "TempleHinduOutlined",
    ],
    TechnologyAndCommunication: [
        "ComputerOutlined",
        "PhoneAndroidOutlined",
        "SmartphoneOutlined",
        "TabletMacOutlined",
        "TvOutlined",
        "SatelliteAltOutlined",
        "DesktopWindowsOutlined",
        "DeviceHubOutlined",
        "DevicesOutlined",
        "DockOutlined",
        "GamepadOutlined",
        "KeyboardOutlined",
        "LaptopMacOutlined",
        "MemoryOutlined",
        "MouseOutlined",
        "PhonelinkOutlined",
        "ScannerOutlined",
        "SimCardOutlined",
        "SpeakerOutlined",
        "TabletAndroidOutlined",
        "TvOffOutlined",
        "WatchOutlined",
        "DeviceUnknownOutlined",
        "DevicesOtherOutlined",
        "HeadsetMicOutlined",
        "LaptopOutlined",
        "LaptopChromebookOutlined",
        "LaptopWindowsOutlined",
        "PhonelinkOffOutlined",
        "PowerInputOutlined",
        "CallOutlined",
        "ChatOutlined",
        "EmailOutlined",
        "MessageOutlined",
        "ContactPhoneOutlined",
        "ContactSupportOutlined",
        "MicExternalOnOutlined",
        "OutletOutlined",
        "RouteOutlined",
        "GpsFixedOutlined",
        "GpsNotFixedOutlined",
    ],
};

const includedIcons = Object.values(IconCategories).flat();

export const Icons = Object.entries(IconsMaterial).reduce((acc, [key, value]) => {
    if (includedIcons.includes(key)) {
        acc[key] = value;
    }
    return acc;
}, {});

const emojiJsonUrl = 'https://unpkg.com/emoji-datasource-apple@15.1.2/emoji.json';

interface IconOrCategory {
    type: 'Emoji' | 'Icon' | 'Category' | 'Empty';
    name: string;
    tooltip?: string;
}

interface IconsGridProps {
    onSelect: (item: IconSelectionInfo) => void;
    initialIcon?: IconSelectionInfo;
}

export const IconsGrid: React.FC<IconsGridProps> = ({ onSelect, initialIcon }) => {
    const [tabValue, setTabValue] = useState(initialIcon?.isEmoji ? 0 : 1);
    const [searchQuery, setSearchQuery] = useState('');
    const [emojis, setEmojis] = useState<EmojiInfo[]>([]);

    const [skinToneModifiers, setSkinToneModifiers] = useState([]);
    const [selectedSkinTone, setSelectedSkinTone] = useState('');

    const numColumns = 7;
    const gridWidth = 335;
    const rowHeight = 48;

    useEffect(() => {
        fetch(emojiJsonUrl)
            .then(response => response.json())
            .then(data => {
                const modifiers = data.filter(emoji => emoji.category === 'Component');
                const otherEmojis = data.filter(emoji => emoji.category !== 'Component');

                setSkinToneModifiers(modifiers);

                const processedEmojis: EmojiInfo[] = otherEmojis.map(emoji => ({
                    name: emoji.name,
                    short_name: emoji.short_name,
                    unified: emoji.unified,
                    category: emoji.category,
                    subcategory: emoji.subcategory,
                    sort_order: emoji.sort_order,
                    skin_variations: emoji.skin_variations || {}
                })).sort((a, b) => a.sort_order - b.sort_order);

                setEmojis(processedEmojis);
            })
            .catch(error => logger.error("Failed to load emojis", error));
    }, []);

    const addSpaceCamelCaseName = useCallback((name: string) => {
        return name.replace(/([A-Z])/g, ' $1').trim();
    }, []);

    const capitalLetterToWords = useCallback((name: string) => {
        return name
            .toLowerCase()
            .replace(/(^|\s)\S/g, letter => letter.toUpperCase());
    }, []);

    const getModifiedEmoji = useCallback((emoji: EmojiInfo) => {
        // If there is a selected skin tone and the emoji has variations, apply the tone
        if (selectedSkinTone && emoji.skin_variations && emoji.skin_variations[selectedSkinTone]) {
            // Get the modified emoji's unified code
            const modifiedUnified = emoji.skin_variations[selectedSkinTone].unified;
            return String.fromCodePoint(...modifiedUnified.split('-').map((cp) => parseInt(cp, 16)));
        }
        // If no skin tone is selected or the emoji has no variations, return the default emoji
        return String.fromCodePoint(...emoji.unified.split('-').map((cp) => parseInt(cp, 16)));
    }, [selectedSkinTone]);

    const [filteredItems, setFilteredItems] = useState<IconOrCategory[]>([]);

    const getItems =  useCallback((): IconOrCategory[] => {
        let items: IconOrCategory[] = [];

        if (tabValue === 0) {
            // Grouping emojis by category
            const categorizedEmojis = emojis.reduce((acc: Record<string, EmojiInfo[]>, emoji: EmojiInfo) => {
                const category = emoji.category || 'Unknown';
                if (!acc[category]) {
                    acc[category] = [];
                }
                acc[category].push(emoji);
                return acc;
            }, {});

            // Flatten the grouped categories into a single array
            return Object.entries(categorizedEmojis).flatMap(([category, emojis]): IconOrCategory[] => {
                // Insert the category as a separate item, marked clearly
                return [
                    { type: 'Category', name: category },
                    ...emojis.map(emoji => ({
                        type: 'Emoji',
                        name: getModifiedEmoji(emoji),
                        tooltip: capitalLetterToWords(emoji.name)
                    } as IconOrCategory))
                ];
            });
        } else {
            Object.entries(IconCategories).forEach(([category, icons]) => {
                items.push({ type: 'Category', name: addSpaceCamelCaseName(category) });

                icons.forEach(icon => {
                    if (includedIcons.includes(icon)) {
                        items.push({
                            type: 'Icon',
                            name: icon,
                            tooltip: addSpaceCamelCaseName(icon)
                        });
                    }
                });
            });
        }

        return items;
    }, [tabValue, emojis, getModifiedEmoji, capitalLetterToWords, addSpaceCamelCaseName]);

    const filterItemsByQuery = useCallback((items) => {
        if (!searchQuery) return items;

        const lowerCaseQuery = searchQuery.toLowerCase();

        return items.filter(item => (item.tooltip ?? item.name).toLowerCase().includes(lowerCaseQuery));
    }, [searchQuery]);

    useEffect(() => {
        const filteredItems = filterItemsByQuery(getItems());

        logger.debug('Filtered items:', filteredItems);

        setFilteredItems(filteredItems);
    }, [tabValue, searchQuery, emojis, selectedSkinTone, filterItemsByQuery, getItems]);

    const handleChangeTab = (event, newValue) => {
        setTabValue(newValue);
    };

    const [rows, setRows] = useState([]);

    useEffect(() => {
        const newRows = [];
        let currentRow = [];

        filteredItems.forEach(item => {
            if (item.type === 'Category') {
                if (currentRow.length > 0) {
                    newRows.push(currentRow); // Push the current row if it has icons
                }
                currentRow = [item]; // Start a new row with the category
                newRows.push(currentRow); // Push the category row immediately
                currentRow = [];
            } else {
                if (currentRow.length < numColumns) {
                    currentRow.push(item);
                }
                if (currentRow.length === numColumns) {
                    newRows.push(currentRow);
                    currentRow = [];
                }
            }
        });

        if (currentRow.length > 0) {
            newRows.push(currentRow); // Add the last row if it has less than numColumns items
        }

        setRows(newRows); // Update the state with the new rows
    }, [filteredItems]);

    const [popoverAchorEl, setPopoverAnchorEl] = useState(null);
    const [iconColor, setIconColor] = useState(initialIcon?.color ?? '#f6f6f8');
    const [iconBackgroundColor, setIconBackgroundColor] = useState(initialIcon?.backgroundColor);
    const [colorSource, setColorSource] = useState<'color' | 'backgroundColor'>();

    useEffect(() => {
        if(!initialIcon?.backgroundColor) {
            const newBackgroundColor = tabValue == 0 ? 'transparent' : '#1976d2';
            setIconBackgroundColor(newBackgroundColor);
        }
    }, [tabValue, initialIcon]); 

    const handlePopoverOpen = (event, cs) => {
        setPopoverAnchorEl(event.currentTarget);
        setColorSource(cs);
    };

    const selectRandomIcon = () => {
        // Select a random item from the filtered list
        if (filteredItems.length > 0) {
            const randomItem = filteredItems[Math.floor(Math.random() * filteredItems.length)];
            onSelect({
                name: randomItem.name,
                color: randomItem.type === 'Emoji' ? 'black' : iconColor,
                backgroundColor: iconBackgroundColor,
                isEmoji: randomItem.type === 'Emoji'
            });
        } else {
            toast.error('No items to select from.');
        }
    };

    const handlePopoverClose = () => {
        setPopoverAnchorEl(null);
    };

    const handleSkinToneSelect = (modifier) => {
        setSelectedSkinTone(modifier.unified);
        handlePopoverClose();
    };

    const handleIconColorSelect = (color) => {
        setIconColor(color.hex);
        handlePopoverClose();
    };

    const renderSelector = () => {
        if (tabValue === 0) {
            // Render skin tone selector for Emojis tab
            return skinToneModifiers.map((modifier) => (
                <IconButton key={modifier.unified} onClick={() => handleSkinToneSelect(modifier)}>
                    {String.fromCodePoint(...modifier.unified.split('-').map((u) => parseInt(u, 16)))}
                </IconButton>
            ))
        } else if (tabValue === 1) {
            // Render icon color selector for Icons tab
            return (
                <>
                    <style>
                        {`.sketch-picker input {
                            background: white !important;
                        }`}
                    </style>
                    <SketchPicker
                        color={iconColor}
                        onChangeComplete={handleIconColorSelect}
                        presetColors={presetColors}
                    />
                </>
            );
        }
    };

    const handleBackgroundColorSelect = (color) => {
        setIconBackgroundColor(color.hex);
        handlePopoverClose();
    }

    const renderBackgroundColorSelector = () => {
        return (
            <>
                <style>
                    {`.sketch-picker input {
                            background: white !important;
                        }`}
                </style>
                <SketchPicker
                    color={iconBackgroundColor}
                    onChangeComplete={handleBackgroundColorSelect}
                    presetColors={presetColors}
                />
            </>
        );
    }

    const RenderRow = ({ index, style }) => {
        const items = rows[index] || [];

        return (
            <div key={`row-${index}`} style={{ ...style, display: 'flex', alignItems: 'center', height: rowHeight }}>
                {items.map((item, idx) => (
                    item.type === 'Category' ? (
                        <Typography
                            key={`category-${idx}`}
                            variant="caption"
                            component="div"
                            style={{ width: '100%' }}
                        >
                            {item.name}
                        </Typography>
                    ) : (
                        <Tooltip key={`tooltip-${idx}`} title={item.tooltip || ''}>
                            <IconButton key={`icon-${idx}`} className='filter-invert'
                                onClick={() => onSelect({
                                    name: item.name,
                                    color: item.type === 'Emoji' ? 'black' : iconColor,
                                    backgroundColor: iconBackgroundColor,
                                    isEmoji: item.type === 'Emoji'
                                } as IconSelectionInfo)}
                                style={{
                                    padding: '0 5px',
                                    margin: '5px',
                                    fontSize: '24px',
                                    aspectRatio: '1 / 1',
                                    color: item.type === 'Emoji' ? 'black' : iconColor,
                                    backgroundColor: iconBackgroundColor
                                }}
                            >
                                {item.type === 'Emoji' ? (
                                    item.name
                                ) : (
                                    React.createElement(IconsMaterial[item.name] || IconsMaterial.Error)
                                )}
                            </IconButton>
                        </Tooltip>
                    )
                ))}
            </div>
        );
    };

    return (
        <Box sx={{ width: gridWidth, height: 330, padding: '10px' }}>
            <Tabs
                value={tabValue}
                onChange={handleChangeTab}
                sx={{ '.MuiTabs-flexContainer': { padding: 0 } }}
            >
                <Tab label="Emojis" />
                <Tab label="Icons" />
            </Tabs>

            <div style={{ display: 'flex', alignItems: 'center' }}>
                <TextField
                    label="Search"
                    variant="outlined"
                    size="small"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    style={{ width: '100%', margin: '10px 10px 10px 0' }}
                />

                <Tooltip title={"Random"}>
                    <IconButton onClick={selectRandomIcon}>
                        <ShuffleOutlinedIcon />
                    </IconButton>
                </Tooltip>

                <Tooltip title={tabValue === 0 ? "Select skin tone" : "Select icon color"}>
                    <IconButton onClick={(e) => handlePopoverOpen(e, 'color')}>
                        {tabValue === 0 ? <Diversity1OutlinedIcon /> : <PaletteIcon /> }
                    </IconButton>
                </Tooltip>

                <Tooltip title={"Select background color"}>
                    <IconButton onClick={(e) => handlePopoverOpen(e, 'backgroundColor')}>
                        <FormatColorFillOutlinedIcon />
                    </IconButton>
                </Tooltip>

                <Popover
                    open={Boolean(popoverAchorEl)}
                    anchorEl={popoverAchorEl}
                    onClose={handlePopoverClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                >
                    {colorSource === 'color' ? renderSelector() : renderBackgroundColorSelector()}
                </Popover>

            </div>

            <div style={{ overflow: 'auto' }}>
                <List
                    height={270}
                    itemCount={rows.length}
                    itemSize={rowHeight}
                    width={gridWidth}
                    overscanCount={5}
                >
                    {RenderRow}
                </List>
            </div>
        </Box>
    );
};

export function generateRandomIcon(): IconSelectionInfo {
    // Select a random icon
    const iconList = Object.keys(Icons);
    const randomIcon = iconList[Math.floor(Math.random() * iconList.length)];

    // Generate a base color
    const baseColor = chroma.random();

    // Generate a color palette based on the base color
    const palette = chroma.scale([baseColor, baseColor.set('hsl.h', '+120'), baseColor.set('hsl.h', '+240')])
        .mode('lch')
        .colors(5);

    // Select a random color from the palette for the background
    const backgroundColor = palette[Math.floor(Math.random() * palette.length)];
    
    // Ensure contrast for the icon color
    const iconColor = chroma.contrast(backgroundColor, 'white') > 4.5 ? '#FFFFFF' : '#000000';

    return {
        name: randomIcon,
        isEmoji: false,
        color: iconColor,
        backgroundColor: backgroundColor
    };
}

export function generateRandomIcons(milestones: PlanMilestoneData[]): { [key: string]: IconSelectionInfo } {
    logger.debug('Generating random icons for milestones', milestones);

    if (!milestones) {
        return {};
    }

    let icons = {};

    for (let i = 0; i < milestones.length; i++) {
        let key = milestones[i].id;
        icons[key] = generateRandomIcon();
    }

    logger.debug('Icons generated', icons);

    return icons;
}