"use client";

import { createContext, Dispatch, ReactNode, SetStateAction, useMemo, useEffect, useState } from "react";
import { ThemeProvider as NextThemeProvider, useTheme } from "next-themes";
import { ThemeProvider as MuiThemeProvider, createTheme } from '@mui/material/styles';
import { Toaster } from "sonner";
import useLocalStorage from "@/lib/hooks/use-local-storage";
import { FirebaseAuthProvider } from "@/context/FirebaseAuthContext";

import logger from "@/lib/logger";
import { PlansProvider } from "@/context/PlansContext";

export const AppContext = createContext<{
  font: string;
  setFont: Dispatch<SetStateAction<string>>;
  isMobile: boolean;
  isTablet: boolean;
  isSmall: boolean;
}>({
  font: "Default",
  setFont: () => { },
  isMobile: false,
  isTablet: false,
  isSmall: false,
});

const ToasterProvider = () => {
  const { theme } = useTheme() as {
    theme: "light" | "dark" | "system";
  };
  return <Toaster theme={theme} />;
};

export default function Providers({ children }: { children: ReactNode }) {
  const [font, setFont] = useLocalStorage<string>("novel__font", "Default");
  const { theme, systemTheme, resolvedTheme } = useTheme(); // Gets the current theme ('light', 'dark', or 'system')
  const [isMobile, setIsMobile] = useState(typeof window !== 'undefined' ? window.innerWidth <= 768 : false);
  const [isTablet, setIsTablet] = useState(typeof window !== 'undefined' ? window.innerWidth <= 1008 : false);
  const [isSmall, setIsSmall] = useState(typeof window !== 'undefined' ? window.innerWidth <= 1248 : false);

  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
      setIsTablet(window.innerWidth <= 1008);
      setIsSmall(window.innerWidth <= 1248);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Debugging
  useEffect(() => {
    logger.debug('Theme:', theme, 'System Theme:', systemTheme, 'Resolved Theme:', resolvedTheme);
  }, [theme, systemTheme, resolvedTheme]);

  // Create a Material-UI theme based on the current theme
  const muiTheme = useMemo(() => {
    const effectiveTheme = theme === 'system' ? (systemTheme) : theme;
    return createTheme({
      palette: {
        mode: effectiveTheme === 'dark' ? 'dark' : 'light',
        primary: {
          main: effectiveTheme === 'dark' ? '#90caf9' : '#1976d2', // light blue in dark mode, darker blue in light mode
        },
        secondary: {
          main: effectiveTheme === 'dark' ? '#f48fb1' : '#d81b60', // soft pink in dark mode, brighter pink in light mode
        },
        background: {
          default: effectiveTheme === 'dark' ? '#121212' : '#fff',
          paper: effectiveTheme === 'dark' ? '#323232' : '#f5f5f5',
        },
        text: {
          primary: effectiveTheme === 'dark' ? '#fff' : '#1a1a1a',
          secondary: effectiveTheme === 'dark' ? '#e0e0e0' : '#4f4f4f',
        },
      },
      components: {
        MuiLinearProgress: {
          styleOverrides: {
            root: {
              backgroundColor: '#e6e6e6' // Background color of the progress bar container
            },
            bar: {
              backgroundColor: '#1976d2' // Color of the progress bar
            }
          }
        },
        MuiSlider: {
          styleOverrides: {
            root: {
              color: '#1976d2', // Color of the filled part of the slider
            },
            track: {
              backgroundColor: '#1976d2', // Color of the track
            },
            rail: {
              backgroundColor: '#e6e6e6', // Color of the unfilled part of the slider
            },
            thumb: {
              backgroundColor: '#1976d2', // Color of the thumb
            },
          },
        },
      },
      // ...other MUI theme configurations
    });
  }, [theme, systemTheme]);

  return (
    <NextThemeProvider
      attribute="class"
      value={{
        light: "light-theme",
        dark: "dark-theme",
      }}
    >
      <MuiThemeProvider theme={muiTheme}>
        <FirebaseAuthProvider>
          <PlansProvider>
            <AppContext.Provider
              value={{
                font,
                setFont,
                isMobile,
                isTablet,
                isSmall
              }}
            >
              <ToasterProvider />
              {children}
            </AppContext.Provider>
          </PlansProvider>
        </FirebaseAuthProvider>
      </MuiThemeProvider>
    </NextThemeProvider>
  );
}
