import React, { createContext, useContext } from 'react';
import { usePlansProvider } from '@/lib/hooks/usePlansProvider';

const PlansContext = createContext(null);

export const PlansProvider = ({ children }) => {
  const plansContextValue = usePlansProvider();
  return (
    <PlansContext.Provider value={plansContextValue}>
      {children}
    </PlansContext.Provider>
  );
};

export const usePlans = () => {
  return useContext(PlansContext);
};
